<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-0">
            <hb-header :divider="false">
                <hb-page-header title="Page Menus">
                    <template v-slot:description>
                        <div>import { HbPageMenu } from 'hummingbird-aviary';</div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <v-row class="ma-0 pa-0">
            <v-col :cols="!showTertiary || $vuetify.breakpoint.xs ? 12 : 10" class="ma-0 pa-0 pt-2" :class="{'pr-6' : showTertiary && !$vuetify.breakpoint.xs}">
                <hb-card title="Page Menu Example + Code" class="mt-4 mb-6">
                    <div class="pa-6">
                        Example is the page menu navigation on that appears on the right side of this page, click this menu icon to toggle the menu: <hb-btn class="px-1" :class="{'pr-3' : $vuetify.breakpoint.xs}" icon tooltip="Sidebar" @click="toggleSidebar" :active="showTertiary">mdi-table-actions-custom-1</hb-btn>
                    </div>

                <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;v-container class="ma-0 pa-0" fluid>
    &lt;v-row class="ma-0 pa-0">
        &lt;v-col :cols="!showTertiary || $vuetify.breakpoint.xs ? 12 : 10" class="ma-0 pa-0 pt-4" :class="{'pr-6' : showTertiary && !$vuetify.breakpoint.xs}">
            &lt;router-view>&lt;/router-view>
        &lt;/v-col>


        &lt;v-col :cols="showTertiary ? 2 : 0" class="ma-0 pa-0" v-if="showTertiary">
            &lt;hb-page-menu v-model="showTertiary">
                &lt;v-list dense>
                    &lt;v-list-item-group v-model="selection" mandatory>
                        &lt;v-list-item v-for="item, i in menuItems" exact :to="'/wherever-you-want' + item.path" :key="item.title">
                            <span v-pre>{{item.title}}</span>
                        &lt;/v-list-item>
                    &lt;/v-list-item-group>
                &lt;/v-list>
            &lt;/hb-page-menu>
        &lt;/v-col>
    &lt;/v-row>
&lt;/v-container>
</pre>
                    </hb-card>
                </hb-card>

                <hb-card title="Props, Slots, &amp; Events" class="my-6 pb-1">
                    <hb-data-table
                        :headers="vuetifyHeaders"
                        :items="vuetifyItems"
                        class="pa-6"
                    >
                        <template v-slot:item.resource="{ item }">
                            <hb-link :href="item.resource" target="_blank">{{ item.resource }}</hb-link>
                        </template>
                    </hb-data-table>
                </hb-card>
            </v-col>


            <v-col :cols="showTertiary ? 2 : 0" class="ma-0 pa-0" v-if="showTertiary">
                <hb-page-menu v-model="showTertiary">
                    <v-list dense>
                        <v-list-item-group v-model="selection" mandatory>
                            <v-list-item v-for="item, i in menuItems" exact :key="item.title">
                                {{item.title}}
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </hb-page-menu>
            </v-col>
        </v-row>
        
    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemPageMenus",
        data: function() {
            return {
                showTertiary: true,
                selection: '',
                menuItems: [
                    { 
                      title: 'Voldemort',
                    },
                    { 
                      title: 'Bellatrix',
                    },
                    { 
                      title: 'Dumbledore',
                    }
                ],
                vuetifyHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                    { text: 'Resource', value: 'resource' },
                ],
                vuetifyItems: [
                    { name: 'all vuetify props, slots, & events', description: 'All the same vuetify props, slots, & events for "<v-navigation-drawer>" can be used for "<hb-page-menu>". See the vuetify documentation link in the next column for the list of available props, slots, & events.', resource: 'https://v2.vuetifyjs.com/en/api/v-navigation-drawer/' },
                ],
            };
        },
        methods: {
            toggleSidebar(){
                this.showTertiary = !this.showTertiary;
                EventBus.$emit("design-system-sidebar-toggled");
            },
        }
    }
</script>

<style scoped>

</style>